
//
//import { UserService } from '@app/_common';
//import {AppConfig} from '@app/_config/';
//
//@Injectable({
//  providedIn: 'root'
//})
export interface ViewAction {

edit(id: string | number):void;
view(id: string | number):void;
list(id: string | number | null):void;
add(id: string | number | null):void;

}