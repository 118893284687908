<div class="layout-wrapper" id="wrapper" [ngClass]="{
  menuOpened: sysService.isLoggedIn()
}">
  <!-- <div class="p-bar" *ngIf="sysService.isLoggedIn()"></div> -->

  <f-header></f-header>
  <div class="{{sysService.isLoggedIn() ? 'mc':''}}">
    <!-- <p-messages key="msgs"></p-messages> -->
    <p-toast position="top-center" key="msgs" [style]="{width: '27%'}"></p-toast>
    <router-outlet></router-outlet>
  </div>
</div>

<footer class="footer">
  &#169; Copyright: Legal Services NYC
</footer>
<p-confirmDialog [style]="{width: '550px'}" #cd>

  <p-footer>
    <button type="button" pButton label="Ok" class="button-theme w-8rem h-3rem btn-fmly" (click)="cd.accept()"></button>

    <!-- <button type="button" pButton label="No" class="button-theme-cl w-8rem h-3rem btn-fmly"
      (click)="cd.reject()"></button> -->
  </p-footer>
</p-confirmDialog>