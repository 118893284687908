import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'f-error',
  templateUrl: './field-error.component.html',
  styleUrls: ['./field-error.component.scss']
})
export class FieldErrorComponent {
  @Input() msg: string;
  @Input() err: boolean;
}