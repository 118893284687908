import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';
import { AppConstants } from '@app/_config';
import { SysService, UserService } from '@app/_common';
import { StaticData } from './static.data';
@Injectable({
  providedIn: 'root'
})
export class StaticService {


  productStatus: any = [];
  activeStatus: any = [];
  piedmontConnectAutocomplete: any = [];
  merchantId: any = [];
  inactiveReason: any = [];
  biller: any = [];
  // PiedmontSalesPerson: any = [];
  State: any = [];
  Permission: any = [];
  NoPermission: any = [];
  Complaint: any = [];
  PrimaryLanguage: any[];
  agentFeeSplitBusinessAccount: any = [];
  agentFeeSplitAccountType: any = [];
  agentFeeSplitFederalTaxClassification: any = [];
  expenseMemo: any = [];
  frozen: any = [];
  agentViewable: any = [];
  contactBySms: any = [];
  fundingMethod: any = [];
  contactByEmail: any = [];
  officeLocStatus: any = [];


  // static service for aliis
  Status: any = [];
  confirmStatus: any = [];
  Country: any = [];
  role_id: any = [];
  callType: any = [];
  VeteranStatus: any = [];
  callerType: any = [];
  is_not_lsnyc_issue: any = [];
  pronoun: any = [];
  search_by: any = [];
  is_proxy_options: any = [];


  //Arjun: Agent
  veteranStatus: any = [];
  IsProxy: any = [];
  LegalProxyVerification: any = [];
  LegalProxyRelatioship: any = [];
  ProvideProxy: any = [];
  issueDispositionStatus: any = [];
  issueProfileType: any = [];
  TableSearchFields: any = [];

  constructor() {
    this.productStatus = StaticData.productStatus;
    this.activeStatus = StaticData.activeStatus;
    this.piedmontConnectAutocomplete = StaticData.piedmontConnectAutocomplete;
    this.merchantId = StaticData.merchantId;
    this.inactiveReason = StaticData.inactiveReason;
    // this.biller = StaticData.biller;
    // this.PiedmontSalesPerson = StaticData.PiedmontSalesPerson;
    this.frozen = StaticData.frozen;
    this.State = StaticData.State;
    this.contactBySms = StaticData.contactBySms;
    this.contactByEmail = StaticData.contactByEmail;
    this.officeLocStatus = StaticData.officeLocStatus;
    this.agentViewable = StaticData.agentViewable;
    this.Permission = StaticData.Permission;
    this.NoPermission = StaticData.NoPermission;
    this.Complaint = StaticData.Complaint;
    this.PrimaryLanguage = StaticData.PrimaryLanguage;
    this.agentFeeSplitBusinessAccount = StaticData.agentFeeSplitBusinessAccount;
    this.agentFeeSplitAccountType = StaticData.agentFeeSplitAccountType;
    this.agentFeeSplitFederalTaxClassification = StaticData.agentFeeSplitFederalTaxClassification;
    this.expenseMemo = StaticData.expenseMemo;
    this.fundingMethod = StaticData.fundingMethod;


    // aliis (s)
    this.Status = StaticData.Status;
    this.role_id = StaticData.role_id;
    this.callType = StaticData.callType;
    this.VeteranStatus = StaticData.VeteranStatus;
    this.callerType = StaticData.callerType;
    this.is_not_lsnyc_issue = StaticData.is_not_lsnyc_issue;
    this.pronoun = StaticData.pronoun;
    this.search_by = StaticData.search_by;
    this.is_proxy_options = StaticData.is_proxy_options;
    this.Country = StaticData.Country;
    this.confirmStatus = StaticData.confirmStatus;

    //Arjun: Agent
    this.veteranStatus = StaticData.VeteranStatus;
    this.IsProxy = StaticData.IsProxy;
    this.LegalProxyVerification = StaticData.LegalProxyVerification;
    this.LegalProxyRelatioship = StaticData.LegalProxyRelatioship;
    this.ProvideProxy = StaticData.ProvideProxy;
    this.issueDispositionStatus = StaticData.IssueDispositionStatus;
    this.issueProfileType = StaticData.IssueProfileType;
    this.TableSearchFields = StaticData.tableSearchFields;
  }
}
