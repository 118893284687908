import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';
import { AppConstants } from '@app/_config';
import { ActivatedRoute } from '@angular/router';
import { SysService } from './sys.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor() { }


  async login(data: any, route: ActivatedRoute, sysService: SysService) {
    await this.saveToken(environment.backend.spring ? data.access_token : data.token); // TODO set the right token key
    await this.saveUser(data.user);
    const returnUrl = route.snapshot.queryParams.returnUrl || '/';
    sysService.appInit();
    sysService.navigate([returnUrl]);
  }

  async changeSite(data: any, route: ActivatedRoute, sysService: SysService) {
    await this.saveToken(environment.backend.spring ? data.access_token : data.token); // TODO set the right token key
    const user = this.getUser();
    if (user) {
      if (environment.backend.spring)
        user.access_token = data.access_token;
      else
        user.token = data.token;
      await this.saveUser(user);
    }
    const returnUrl = route.snapshot.queryParams.returnUrl || '/';
    sysService.navigate([returnUrl]);
  }

  signOut(): void {
    window.localStorage.clear();
  }

  saveToken(token: string): void {
    window.localStorage.removeItem(AppConstants.tokenKey);
    window.localStorage.setItem(AppConstants.tokenKey, token);
  }

  getToken(): string | null {
    return window.localStorage.getItem(AppConstants.tokenKey);
  }

  saveUser(user: any): void {
    window.localStorage.removeItem(AppConstants.userKey);
    window.localStorage.setItem(AppConstants.userKey, JSON.stringify(user));
  }

  getUser(): any {
    const user = window.localStorage.getItem(AppConstants.userKey);
    if (user) {
      return JSON.parse(user);
    }
    return null;
  }

  isLoggedIn(): boolean {
    return (this.getUser() !== null);
  }

  hasPermission(key: any = [], user): boolean {
    if (user && user.permissions) {
      let v = false;
      for (let i = 0; i < key.length; i++) {
        v = user.permissions.includes(key[i]);
        if (v) {
          break;
        }
      }
      return v;
    }
    return true;
  }


  scrollTo(top = 0, left = 0): void {
    document.querySelector('body')?.scroll({
      top: top,
      left: left,
      behavior: 'smooth'
    });
  }

}
