import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { HttpUrlEncodingCodec, Model } from '@app/_common';
import { AppHeader } from '@app/_config';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  httpParams: HttpParams;
  constructor(private http: HttpClient, private urlenc: HttpUrlEncodingCodec) {
    this.httpParams = new HttpParams({ encoder: new HttpUrlEncodingCodec() });
  }

  private getBody(username: string, password: string) {
    if (environment.backend.spring)
      return this.httpParams.set("username", username).set("password", password).set("grant_type", "password");
    else if (environment.backend.node || environment.backend.php)
      return { username, password };
  }

  login(username: string, password: string): Observable<any> {
    return this.http.post(environment.auth.login, this.getBody(username, password), { headers: AppHeader.getLoginHeader() });
  }
  
  register(model:Model, contentType = 'application/json') {
    return this.http.post(environment.auth.register, model.form.value, { headers: AppHeader.getLoginHeader() });
  }

//  register(username: string, email: string, password: string): Observable<any> {
//    return this.http.post(environment.auth.register, {
//      username,
//      email,
//      password
//    },  { headers: AppHeader.getLoginHeader() });
//  }
}
