import { OnInit, Component, ViewChild, ElementRef, Inject, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService, SysService, UserService } from '@app/_common';
import { BehaviorSubject, Observable } from 'rxjs';
import { NavigationExtras, NavigationStart, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  constructor(
    @Inject(DOCUMENT) private document, private elementRef: ElementRef,
    private http: HttpClient,
    public sysService: SysService,
    private apiService: ApiService,
    private router: Router,
    private userService: UserService, private _zone: NgZone

  ) {
    this.sysService.changeTheme(this.sysService.theme, this.sysService.logo);
  }

  ngOnInit(): void {
    this.sysService.appRefresh();
  }
  

  scrollTo(v = 0): void {
    document.querySelector('body')?.scroll({
      top: v,
      left: 0,
      behavior: 'smooth'
    });
    // document.querySelector('body').scrollIntoView({
    //   behavior: 'smooth'
    // });
  }
}

