import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { AuthService } from '@app/_common';


//export class AppValidator  {
//
//
//}
export const passwordMatch: any = (group: FormGroup) => {
  const pass = group.controls.Password.value;
  const confirmPass = group.controls.ConfirmPassword.value;
  return pass === confirmPass ? null : { notSame: true };
}

export const minValue: any = (group: FormGroup) => {
  const val = group.controls.AmountToTransfer.value;
  return val > 0.00 ? null : { minValue: true };
}

export const miniValue: any = (group: FormGroup) => {
  const val = group.controls.Amount.value;
  return val > 0.00 ? null : { miniValue: true };
}

