import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpHeaders } from "@angular/common/http";
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService, SysService, View, ViewAction } from '@app/_common';
import { FormDefault, Model } from './model.view';
import { environment } from '@environments/environment';
import { HttpUrlEncodingCodec } from './http-url-encoding-codec';
import { AppConstants } from '@app/_config';
import { finalize, first } from 'rxjs/operators';
import { ConfirmationService } from 'primeng/api';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Router, UrlSegmentGroup, PRIMARY_OUTLET, UrlSegment } from '@angular/router';
@Injectable()
export class ViewFactory {

  constructor(private router: Router, private location: Location, public sysService: SysService, private apiService: ApiService, private confirmationService: ConfirmationService, private formBuilder: FormBuilder) {
  }

  newView() {
    const v = new View(this.router, this.location, this.sysService, this.apiService, this.confirmationService);
    v.model= this.emptyForm();
    return v;
  }
  
   newViewPopup() {
    const v = new View(this.router, this.location, this.sysService, this.apiService, this.confirmationService);
    v.setPopup(true);
    v.model= this.emptyForm();
    return v;
  }

  private emptyForm() {
    return new Model(this.formBuilder.group({
      id: [],
      noformdefined: []
    }), this.sysService, new FormDefault(), '');
  }
}

